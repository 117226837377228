import getDeviceInfo from '@/utils/device'

const serverPath = process.env.VUE_APP_SEVER_PATH
const wxAppid = process.env.VUE_APP_WX_APPID
const aliAppid = process.env.VUE_APP_ALI_APPID
const projectPath = process.env.VUE_APP_PROJECT_PATH

const state = {
    SEVER_PATH_NUWA: `${serverPath}/nuwa-api`,
    SEVER_PATH_BEGONIA: `${serverPath}/begonia-api`,
    SEVER_PATH_CUPID: `${serverPath}/cupid-api`,
    WX_APPID: wxAppid,
    ALI_APPID: aliAppid,
    PROJECT_PATH: projectPath,
    deviceInfo: {
        mobile: false,
        wechat: false,
        ios: false,
        android: false,
        pc: true,
        wxMini: false,
        baiduSwan: false,
        tabletPc: false,
        UA: '',
    },
    beianList: [
        {
            host: 'xuebing.cn',
            company: '北京雪饼科技有限公司',
            address: '北京市海淀区志新村小区30号楼二层2099室',
            bei: '',
            icp: '京ICP备2022030529号-1',
            tel: '010-53350438',
        },
        {
            host: 'xbky.net',
            company: '北京雪饼文化有限公司',
            address: '北京市大兴区纪百户街18号院2号楼3层207',
            bei: '',
            icp: '京ICP备2023002068号-1',
            // tel: '010-53350438',
        },
    ],
}

const mutations = {
    SET_DEVICE_INFO: (state, info) => {
        state.deviceInfo = info
    },
}
const actions = {
    setDeviceInfo({ commit }) {
        commit('SET_DEVICE_INFO', getDeviceInfo())
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}
